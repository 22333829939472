main div.gx-main-content-wrapper {
  padding: 10px 10px 0;
}
.ant-modal-header {
  color: #fff !important;
  background: #003366 !important;
  border-bottom: 1px solid #001b37 !important;
  border-radius: 6px 6px 0 0 !important;
}
.ant-modal-header .ant-row {
  margin: 0px !important;
}
.ant-modal-header .ant-col,
.ant-modal-close {
  color: #fff !important;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 0px) !important;
    margin: 0px !important;
    top: 0px !important;
  }
  .ant-modal-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  .ant-modal-wrap {
    overflow: hidden !important;
  }
}
.ant-modal-root {
  overflow: hidden !important;
}
.ant-modal-footer {
  padding: 0px !important;
}
.ant-modal-footer button,
.ant-modal-footer button:hover,
.ant-modal-footer button:focus {
  min-height: 45px !important;
  background-color: #014385 !important;
  border-color: #014385 !important;
}
.ant-modal-footer button:nth-child(n) {
  border-right: 2px solid #fff;
  border-radius: 0px !important;
  width: 100% !important;
  margin: 0px !important;
  margin-right: 1px !important;
  padding: 0px !important;
}
.ant-modal-footer button:nth-last-child(1) {
  border-right: 0px solid #fff !important;
  border-radius: 0px !important;
  width: 100% !important;
  margin: 0px !important;
  margin-right: 0px !important;
  padding: 0px !important;
}
.ant-modal-footer {
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.ant-col.ant-col-24.ant-form-item-label {
  padding-left: 16px !important;
}
.ant-btn-round {
  padding: 0px 15px !important;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 5px 8px !important;
  font-weight: 400 !important;
  font-family: Mulish, Arial, Helvetica, san-serif !important;
  font-size: 12px !important;
}
.ant-table.ant-table-small .ant-table-thead > tr > th {
  font-size: 13px !important;
}
.ant-table-body {
  overflow: auto auto !important;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #007bc1 !important;
  color: #fff !important;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #fff !important;
}
.ant-modal-footer button:hover {
  color: #fff !important;
}
@media screen and (min-width: 992px) {
  .ant-layout-header,
  .gx-layout-sider-header {
    max-height: 50px;
  }
  aside .gx-icon-btn.icon.icon-menu-unfold.gx-text-white {
    margin-top: -10px;
  }
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: 4px !important;
}
.gx-sidebar-content,
.gx-sidebar-content .gx-layout-sider-scrollbar {
  height: 95% !important;
  /* min-height: 100% !important; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(64, 67, 187);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007bc1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007bc1;
}
table tr:nth-child(even) td,
table tr:nth-child(even) td:hover {
  background-color: #efeded !important;
}
.gx-sidebar-content li.ant-menu-item.ant-menu-item-only-child,
.gx-sidebar-content .ant-menu-item.ant-menu-item-only-child,
.gx-sidebar-content
  .ant-layout-sider
  .ant-menu-submenu
  > .ant-menu.ant-menu-sub
  .ant-menu-item {
  padding-left: 70px !important;
  padding-right: 0px !important;
}
.gx-sidebar-content .ant-menu-item.ant-menu-item-only-child span {
  font-size: 12px !important;
  line-height: 20px !important;
}
.gx-sidebar-content .ant-menu-title-content span {
  font-size: 13px;
}
.ant-form-item {
  margin-bottom: 7px !important;
}
.ant-form-item .ant-col.ant-col-24.ant-form-item-label {
  max-height: 35px !important;
}
.gx-table-responsive .ant-pagination {
  display: none !important;
}
.ant-pagination {
  text-align-last: end !important;
  margin-right: 15px !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text {
  height: 13px !important;
  line-height: 13px !important;
}
.ant-pagination > li {
  margin-bottom: 0px !important;
}
.textwrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
.framed-layout .gx-layout-sider-scrollbar{
  height: 100% !important;
}
.topbarProfileImage{
  object-fit: fill;
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}